<template>
    <div>
        
    </div>
</template>
<script>
import * as dd from 'dingtalk-jsapi';
import util from '../../libs/util.js'
import axios from 'axios'

export default {

     mounted() {

        let that = this
        dd.ready(function () {
            let corpId = 'dinga3582e3f869ccca3bc961a6cb783455b';
            dd.runtime.permission.requestAuthCode({

                corpId: corpId, //三方企业ID
                onSuccess: function(result) {
                    that.getUser(result.code)
                },
                onFail : function(err) {
                  alert(JSON.stringify(err))
                }

            });
        });

        
    },

    methods: {
        getUser(code) {
            axios.post('https://ydwx.83277777.com/api/ding/getUser?code='+code).then(res => {
                    let user = res.data.data.result
                    console.log(this.user)
                    if (user != undefined) {
                        util.cookies.set('username', user.name)
                        util.cookies.set('token', user.name)
                        util.cookies.set('avatar', user.avatar)
                        this.$router.replace(this.$route.query.redirect || '/')
                        
                    }


                }).catch((e) => {
                    console.log(e)
                })
        },

    }
}
</script>